// Customizable Area Start
//@ts-nocheck
import React from "react";
import {
Container,
Button,
Typography,
Grid,
 Paper, TableRow, TableHead, TableContainer, TableCell, TableBody, Table,
} from "@material-ui/core";
import AnalyzeController, {
   Props,
   } from "./AnalyzeController.web";
   import { createStyles, Theme,withStyles } from '@material-ui/core/styles';
   import { withRouter } from "react-router";
// Customizable Area End

const Title = ({ caption, classes }: any) => {
    return (
        <Grid item xs={12} className={classes.titleContainer}>
            <Grid container direction="row" alignItems="center" justify="space-between" className={classes.container}>
                <Grid item xs={12} md={6} >
                    <Typography variant='h6' className={classes.titleColor}>{caption}</Typography>
                </Grid>
                <Grid item xs={12} md={6}>
                    <Grid container direction='row' justifyContent='flex-end' alignItems='center' spacing={3}>
                        <Grid item>
                            <Button
                                color="primary"
                                variant="outlined">
                                <Typography variant="body2" className={classes.btntext} >Download</Typography>
                            </Button>
                        </Grid>
                        <Grid item>
                            <Button
                                color="primary"
                                variant="outlined">
                                <Typography variant="body2" className={classes.btntext}  >View More</Typography>
                            </Button>
                        </Grid>
                    </Grid>

                </Grid>
            </Grid>
        </Grid>
    );
};
const StyledTableCell = withStyles((theme: Theme) =>
    createStyles({
        head: {
            backgroundColor: "rgba(104, 140, 207, 0.12)",
            color: theme.palette.common.black,
            fontWeight: "bold"
        },
        body: {
            fontSize: 14,
        },
    }),
)(TableCell);

const createData=(Sno: number, Source: string, age: string, total: string)=>{
    return { Sno, Source, age, total };
}
const sourceHeading = [
    {
        id: 1,
        title: "S.No"
    },
    {
        id: 2,
        title: "Source"
    },
    {
        id: 3,
        title: "%age contribution"
    },
    {
        id: 4,
        title: "Total Emissions"
    },
]
const locationHeading = [
    {
        id: 1,
        title: "S.No"
    },
    {
        id: 2,
        title: "Location"
    },
    {
        id: 3,
        title: "%age contribution"
    },
    {
        id: 4,
        title: "Total Emissions"
    },
]
const scopeHeading = [
    {
        id: 1,
        title: "S.No"
    },
    {
        id: 2,
        title: "Scope"
    },
    {
        id: 3,
        title: "%age contribution"
    },
    {
        id: 4,
        title: "Total Emissions"
    },
]
const Sourcerows = [
    createData(1, "Bussiness Trave", "27%", "1000 tCO2e"),
    createData(2, "Grid Electricity", "32%", "500 tCO2e"),
    createData(3, "Refrigent R32", "51%", "250 tCO2e"),
    createData(4, "Employee Commute-Car", "25%", "1200 tCO2e"),
    createData(5, "Purchased Goods & Services", "48%", "1000tCO2e"),
];
const Locationrows = [
    createData(1, "Location C", "27%", "1000 tCO2e"),
    createData(2, "Location Y", "32%", "500 tCO2e"),
    createData(3, "Location A", "51%", "250 tCO2e"),
    createData(4, "Location G", "25%", "1200 tCO2e"),
    createData(5, "Location S", "48%", "1000tCO2e"),
];
const Scoperows = [
    createData(1, "Scope 1", "27%", "1000 tCO2e"),
    createData(2, "scope 2", "32%", "500 tCO2e"),
    createData(3, "Scope 3", "51%", "250 tCO2e"),

];
const TableRender = ({ classes, Tableheading, rows }: any) => {
    return (
        <Grid item xs={12}>
            <Grid container justifyContent='center' alignItems='center'>
                <Grid item xs={10} className={classes.container}>
                    <TableContainer component={Paper} className={classes.paper}>
                        <Table className={classes.table} stickyHeader aria-label="sticky table">
                            <TableHead>
                                <TableRow>
                                    {Tableheading.map((_data: any) => {
                                        return (<>
                                            <StyledTableCell align='left' key={_data?.id}>{_data?.title}</StyledTableCell></>
                                        )
                                    })}
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {rows.map((row: any) => {
                                    return (
                                        <TableRow key={row?.Sno}>
                                            <StyledTableCell component="th" scope="row"> {row.Sno}</StyledTableCell>
                                            <StyledTableCell align="left">{row.Source}</StyledTableCell>
                                            <StyledTableCell align="left" width={"180px"}>{row.age}</StyledTableCell>
                                            <StyledTableCell align="left" width={"180px"}>{row.total}</StyledTableCell>
                                        </TableRow>
                                    )
                                }
                                )}
                            </TableBody>
                        </Table>
                    </TableContainer>

                </Grid>
            </Grid>

        </Grid>

    )
}


class AnalyzeWeb extends AnalyzeController {
constructor(props: Props) {
super(props);
   // Customizable Area Start
   // Customizable Area End
}

render() {
     // Customizable Area Start
const { classes } = this.props;
return (   
    <Container maxWidth="xl">
    <Title {...{ caption: "Top Emissions By source", classes }} />
    <TableRender {...{ classes, Tableheading: sourceHeading, rows: Sourcerows }} />
    <Title {...{ caption: "Top Emissions By Location", classes }} />
    <TableRender {...{ classes, Tableheading: locationHeading, rows: Locationrows }} />
    <Title {...{ caption: " Emissions By Location", classes }} />
    <TableRender {...{ classes, Tableheading: scopeHeading, rows: Scoperows }} />

</Container>


 
       )
 // Customizable Area End
}
 
}
// Customizable Area Start
const AnalyzeRouter= withRouter(AnalyzeWeb)
export default withStyles((theme) =>
createStyles({
    btntext: {
        textTransform: "capitalize"
    },
    table: {
        minWidth: 700,
    },
    container: {
        paddingTop: "2rem"
    },
    paper: {
        borderRadius: "10px"
    },
    titleContainer:{
        paddingLeft:"7rem",
        paddingRight:"7rem"
    },
    titleColor:{
        color:"blue"
    }
   
})
)(AnalyzeRouter);
// Customizable Area End
