import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import { RouterProps } from "react-router";

// Customizable Area Start

// Customizable Area End

export const configJSON = require("./config");
import { StyledProps } from "@material-ui/core/styles";

export type Props = RouterProps &
  // Customizable Area Start
  StyledProps & {
    id: string;
    navigation: any;
    match: any;
    openToastHandler: any;
    // Customizable Area End
  };

interface S {
  // Customizable Area Start
  password: string;
  email: string;
  enablePasswordField: boolean;
  placeHolderEmail: string;
  placeHolderPassword: string;
  imgPasswordVisible: any;
  labelHeader: string;
  btnTxtLogin: string;
  labelRememberMe: string;
  btnTxtSocialLogin: string;
  open: boolean;
  opensuccess: boolean;
  signupObj: any  // Customizable Area End
  errors: any;
  fields: any;
  snackOpen: boolean;
  toastMessage: any;
  isAccountCreation: boolean;
  checkbox:any;
}

interface SS {
  // Customizable Area Start
  id: any;
  // Customizable Area End
}

export default class EmailAccountSignUpController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  apiEmailLoginCallId: string = "";
  signUpApiCallId: string = "";
  // Customizable Area End
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
    ];

    this.state = {
      email: "",
      password: "",
      checkbox:false,
      enablePasswordField: true,
      placeHolderEmail: configJSON.placeHolderEmail,
      placeHolderPassword: configJSON.placeHolderPassword,
      imgPasswordVisible: configJSON.imgPasswordVisible,
      labelHeader: configJSON.labelHeader,
      btnTxtLogin: configJSON.btnTxtLogin,
      labelRememberMe: configJSON.labelRememberMe,
      btnTxtSocialLogin: configJSON.btnTxtSocialLogin,
      open: false,
      opensuccess: false,
      signupObj: {},
      errors: {
        firstname: '',
        lastname: '',
        email: '',
        phonenumber: '',
        password: '',
        conformpassword: '',
        checkbox: '',
      },
      fields: {
        firstname: "",
        lastname: '',
        email: '',
        phonenumber: '',
        password: "",
        conformpassword: '',
        checkbox:false
      },
      snackOpen: false,
      toastMessage: '',
      isAccountCreation: false
    };


    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }
  async componentDidMount() {
    this.send(new Message(getName(MessageEnum.RequestUserCredentials)));
  }
  async receive(from: string, message: Message) {
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );
      let responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      const errorReponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );
      if (apiRequestCallId != null) {
        if (apiRequestCallId === this.signUpApiCallId) {
          this.handleSignUpApiResponse(responseJson);
        }
        if (apiRequestCallId === this.apiEmailLoginCallId) {
          if (responseJson && responseJson.meta && responseJson.meta.token) {
            alert('sucesss');
            runEngine.unSubscribeFromMessages(this, this.subScribedMessages);

          } else {
            //Check Error Response
            this.parseApiErrorResponse(responseJson);
          }
          this.parseApiCatchErrorResponse(errorReponse);
        }
      }
    }
    // Customizable Area End
  }


  handleSignUp = () => {
    const { fields } = this.state;
    let validationErrors: any = {};
    Object.keys(fields).forEach(name => {
      const error = this.validateSignUpFields(name, fields[name]);
      if (error && error.length > 0) {
        validationErrors[name] = error;
      }
    });
    if (Object.keys(validationErrors).length > 0) {
      this.setState({ errors: validationErrors });
      return;
    }
    if (this.state.signupObj.password != this.state.signupObj.conformpassword) {
      this.setState({ snackOpen: true, toastMessage: 'Password and confirm password must be same' })
      return
    }
    const headers = {
      "Content-Type": configJSON.validationApiContentType,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.signUpApiCallId = requestMessage.messageId;
    const httpBody = {
      "data": {
        "type": "email_account",
        "attributes": {
          "first_name": this.state.signupObj.firstname,
          "last_name": this.state.signupObj.lastname,
          "email": this.state.signupObj.email,
          "full_phone_number": this.state.signupObj.phonenumber,
          "password": this.state.signupObj.password,
          "password_confirmation": this.state.signupObj.conformpassword,
          "activated": "true"
        }
      }
    }
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.signupUrl
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.loginAPiMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }
  handleSignUpApiResponse = (responseJson: any) => {
    if (responseJson && responseJson.meta) {
      this.setState({ snackOpen: true, toastMessage: 'Account Created successfully', isAccountCreation: true })

      console.log(responseJson)
    }

  };
  validateSignUpFields = (name: any, value: any) => {
    switch (name) {
      case "email":
        return this.handleEmailValidation(value);
      case "password":
        return this.handlePasswordValidation(value);
      case "firstname":
        if (!value) {
          return "First name is Required";
        } else {
          return "";
        }
      case "lastname":
        if (!value) {
          return "Last name is Required";
        } else {
          return "";
        }
      case "phonenumber":
        return this.handlePhoneNumberValidation(value);
      case "conformpassword":
        return this.handleConfirmPasswordValidation(value);
      case 'checkbox':
        return this.handleCheck()

      default: {
        return "";
      }
    }
  }
  handleSnakbarClose = () => {
    this.setState({ snackOpen: false });
    if (this.state.isAccountCreation) {
      this.props.history.push('/login');
    }
  }
  handlePasswordValidation = (value: any) => {
    if (!value) {
      return "Password is Required";
    } else if (value.length < 8 || value.length > 15) {
      return "Please fill at least 8 character";
    } else if (!value.match(/[a-z]/g)) {
      return "Please enter at least lower character.";
    } else if (!value.match(/[A-Z]/g)) {
      return "Please enter at least upper character.";
    } else if (!value.match(/\d/g)) {
      return "Please enter at least one digit.";
    } else {
      return "";
    }
  }
  handleEmailValidation = (value: any) => {
    if (!value) {
      return "Email is Required";
    } else if (
      !value.match(/^[a-z0-9]([a-z0-9_\-\.]*)@([a-z0-9_\-\.]*)(\.[a-z]{2,4}(\.[a-z]{2}){0,2})$/i)
    ) {
      return "Enter a valid email address";
    } else {
      return "";
    }
  }

  handleConfirmPasswordValidation = (value: any) => {
    if (!value) {
      return "Confirm password is Required";
    } else {
      return "";
    }
  }
  handlePhoneNumberValidation = (value: any) => {
    if (!value) {
      return "Phone number is Required";
    } else if (
      !value.match(/^(?:(?:\+|0{0,2})91(\s*[\ -]\s*)?|[0]?)?[789]\d{9}|(\d[ -]?){10}\d$/)
    ) {
      return "Enter a valid phonenumber";

    } else {
      return "";
    }
  }

handleCheck = ()=>{
  if (this.state.checkbox === false){
    return "please select the checkbox"
  }
  else{
    return '';
    
  }
}
}