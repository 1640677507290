// Customizable Area Start
//@ts-nocheck
import React from "react";
import {
Container,
Button,
Typography,
Grid,
Avatar
} from "@material-ui/core";
import EmailAccountLogInController, {
   Props,
   } from "./EamilAcoountLogInController.web";
   import {loginImage,background,tickIcon,logo} from "./assets";
   import { createStyles, withStyles } from '@material-ui/core/styles';
   import TextField from '@material-ui/core/TextField';
   import { withRouter } from "react-router";
   import DialogTitle from '@material-ui/core/DialogTitle';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';

// Customizable Area End
class EmailAccountLogin extends EmailAccountLogInController {
constructor(props: Props) {
super(props);
}
handleChangeInput=(e:any)=>{
    const {name,value}: { name: any; value: any }= e.target
    let data:any=this.state.loginObj
    data[name]=value
this.setState({loginObj:data, errors: {
    ...this.state.errors,
    [name]: this.validate(e.target.name, e.target.value)
  },
  fields: {
    ...this.state.fields,
    [e.target.name]: e.target.value
  }})
  }

  

render() {
const { classes } = this.props;
console.log(this.state.errors)
return (         
<Grid item xs={12} className={classes.cont}>      
   <Grid container direction='row' justifyContent='center' >
      <Grid item xs={12} md={7} className={classes.content}>
         <Container maxWidth="xl" >
            <Grid container justifyContent='center' spacing={3}>
               <Grid item xs={10} style={{ display:'flex', position: 'relative', left: '0px', marginTop: '12px', marginLeft:'90px'}}>
                  <Avatar src={logo} /> 
                  <Typography className={classes.signntext}>Sustainext</Typography> 
               </Grid>
               
               <Grid item xs={12} md={8}>
               <Typography  align='left' className={classes.signinlabel} >Sign in</Typography>       
               </Grid>              
               <Grid item xs={12} md={8}>
                                                     <TextField
                                                      fullWidth
                                                      label="Username"
                                                      id="outlined-size-small"
                                                      variant="outlined"
                                                      size="medium"
                                                      name='username'
                                                      onChange={(e)=>this.handleChangeInput(e)}
                                                      InputLabelProps={{
                                                          shrink: true,
                                                          classes: {
                                                              outlined: classes?.outlinedLabel,
                                                              root: classes?.lableroot
                                                          }
                                                      }}
                                                      InputProps={{
                                                          classes: {
                                                              inputs: classes?.inputs,
                                                              root: classes?.inputfieledroot,
                                                          },
                                                          notched: false,
                                                      }}
                                                      FormHelperTextProps={{
                                                        className: classes.helperText
                                                      }}
                                                      helperText={this.state.errors.username}
                                                      
                                                   />         
               </Grid>             
               <Grid item xs={12} md={8}>
                                                  <TextField
                                                      fullWidth
                                                      label="Password"
                                                      type="password"
                                                      name='password'
                                                      id="outlined-size-small"
                                                      variant="outlined"
                                                      size="medium"
                                                      onChange={(e)=>this.handleChangeInput(e)}
                                                      InputLabelProps={{
                                                          shrink: true,
                                                          classes: {
                                                              outlined: classes?.outlinedLabel,
                                                              root: classes?.lableroot
                                                          }
                                                      }}
                                                      InputProps={{
                                                          classes: {
                                                              inputs: classes?.inputs,
                                                              root: classes?.inputfieledroot,
                                                          },
                                                          notched: false,
                                                      }}
                                                      FormHelperTextProps={{
                                                        className: classes.helperText
                                                      }}
                                                      helperText={this.state.errors.password}
                                                  />
               </Grid>              
               <Grid item xs={12} md={8}>          
                  <Typography className={classes.forgot} align='right' onClick={this.handleOpen}>Forgot Password?</Typography>       
               </Grid>     
               <Grid item xs={12} md={8}>
 <Button style={{background:'#007FFF', color:'white', textTransform: 'capitalize'}}  variant='contained' fullWidth onClick={this.handleLogin}>Sign in</Button>            
               </Grid>       
            </Grid>     
         </Container>  
      </Grid>
      <Grid item xs={12} md={5} className={classes.downMdScreen} >
         <Grid item className={classes.imageContent}>
                                    <img src={background} className={classes.imgGrid} />
            <Grid item className={classes.contentImage}>
               <Grid container justifyContent='center' spacing={3}>
                  <Grid item xs={12} md={8}>            
                     <Typography variant='h5' className={classes.imageText1}>Lorem Ipsum dolar</Typography>                   
                  </Grid>              
                  <Grid item xs={12} md={8}>               
                     <Typography variant='subtitle1' className={classes.imageSubText}>Lorem Ipsum is simply dummy text of the printing and typesetting industry.</Typography>                          
                  </Grid>                     
                  <Grid item xs={12} md={8}> <img src={loginImage}  className={classes.image3} />                  
                  </Grid>                
               </Grid>         
            </Grid>       
         </Grid>     
      </Grid>  
   </Grid>
   <Dialog onClose={this.handleClose} aria-labelledby="simple-dialog-title" open={this.state.open}>
      <DialogTitle id="simple-dialog-title" className={classes.forgottext}>Forgot Password?</DialogTitle>
      <DialogContent className={classes.dcontent}>
      <DialogContentText id="alert-dialog-description" className={classes.contextdes}>
           Enter your registered email below to receive password reset instructions
          </DialogContentText>
            <Grid container justifyContent='center' spacing={2}>       
                <Grid item xs={12}>
                <TextField   
                fullWidth
                 id="outlined-size-small" 
                 label="Email" 
                 size="small"
                    variant="outlined"
                />
                 </Grid> 
               <Grid item xs={12} >
 <Button color='primary' variant='contained' fullWidth onClick={this.closeForgorPopup}>Send</Button>            
               </Grid>       
            </Grid>     
        </DialogContent>
    </Dialog>
    <Dialog onClose={this.handleSuccessClose} aria-labelledby="simple-dialog-title" open={this.state.opensuccess}>
    <DialogTitle id="simple-dialog-title" className={classes.successdialogtitle}>
      <Avatar src={tickIcon} className={classes.avatarimg} />
      </DialogTitle>
      <DialogTitle id="simple-dialog-title" className={classes.successdialogtitle}>
      Email has been sent!
      </DialogTitle>
      <DialogContent className={classes.dcontent}>
      <DialogContentText id="alert-dialog-description" className={classes.contextdes}>
        Please check your inbox and click in the received link to reset a password
        </DialogContentText>
        </DialogContent>
    </Dialog>
</Grid>
)
}
}
// Customizable Area Start
const EmailAccountLoginRouter= withRouter(EmailAccountLogin)
export default withStyles((theme) =>
createStyles({
   inputfieledroot: {
      'label + &': {
          marginTop: 30
      }
  },
  inputs: {
      borderRadius: '8px',
      position: 'relative',
      backgroundColor: '#FFF',
      fontSize: '16px',
      fontStretch: 'normal',
      fontStyle: 'normal',
      lineHeight: 1.19,
      letterSpacing: 'normal',
      padding: '16px 16px',
  },
  lableroot: {
      fontSize: '14px',
      fontStretch: 'normal',
      fontStyle: 'normal',
      lineHeight: 1.14,
      letterSpacing: 'normal',
      color: '#6D838C',
  },
  helperText:{
    color:'red',
},
  outlinedLabel: {
      transform: 'translate(3px, 9px) scale(1)!important'
  },
  imageText1: {
      fontFamily: 'Open Sans',
      fontStretch: 'normal',
      fontWeight: "bold",
      fontStyle: 'normal',
      lineHeight: 1.14,
      letterSpacing: 'normal',
      color: '#ffffff',
  },
  cont:{
    overflow:'hidden'
  },
  imageSubText: {
      fontFamily: 'Open Sans',
      fontStretch: 'normal',
      fontStyle: 'normal',
      lineHeight: 1.14,
      letterSpacing: 'normal',
      color: '#ffffff',
  },
  signntext:{
    margin:'7px',
    fontSize: '1.2rem',
    fontWeight: 800,
    fontStyle: 'normal',
    left: 0
  },
  imgGrid: {
      width: "100%",
      height: '100vh',
      [theme.breakpoints.down('md')]: {
          height: "70vh",
      },
  },
  content: {
      display: 'grid',
      placeItems: 'center'
  },
  image3: {
      width: "200px",
      height: "200px",
      [theme.breakpoints.down('md')]: {
          width: "200px",
          height: "200px",
      },
  },
  contentImage: {
      position: "absolute",
      top: "50%",
      left: "50%",
      transform: "translate(-50%, -50%)"
  },
  imageContent: {
      position: 'relative'
  },
  downMdScreen: {
      [theme.breakpoints.down('md')]: {
          marginTop: "1rem"
      },
  },
  forgottext:{
      textAlign:"center"
  },
  successdialogtitle:{
      textAlign:"center",
      padding: '20px 24px 0px'     
},
  contextdes:{
      width: '18rem',
textAlign: 'center',
marginRight: '0px !important',
[theme.breakpoints.down('xs')]: {
  width: 'auto'
},
  },
  dcontent:{
      padding: '8px 24px 24px'
  },
  avatarimg:{
    margin:'auto',
    width: '50px',
    height: '50px'
  },
  signinlabel:{
      fontSize:'1.2rem',
      fontWeight:800,
    fontStyle:"normal"
  },
  forgot:{
    cursor: 'grab', 
    textDecoration: 'underline',
    color:"blue" 
  },
  MuiFormHelperTextRoot:{
    color:"red"
}
})
)(EmailAccountLoginRouter);
// Customizable Area End
